<template>
  <div class="address-wrapper">
    <div>
      <el-select class="select-small" :popper-append-to-body="true" v-model="form.country" filterable autocomplete="false">
        <el-option v-for="(c, cindex) in COUNTRY_OPTIONS" :key="cindex" :value="c.code" :label="$t(`message.country.${c.code}`)"></el-option>
        <template slot="prefix">
          <div class="select-prefix">{{$t('message.checkout.billingCountry')}}: </div>
        </template>
      </el-select>
    </div>
    <div class="margin-top-35 tip-wrapper">
      <img class="tip-icon" src="../../assets/images/tip.png" width="17" height="17" />
      <span class="tip-text">{{$t('message.checkout.billingCountryTip')}}</span>
    </div>
    <el-button :loading="submitLoading" @click="next" :disabled="!form.country" class="next-button" type="primary">{{$t('message.checkout.nextStep')}}</el-button>
  </div>
</template>
<script>
import { getBrandInfo } from '@/api/checkout';
import COUNTRY_OPTIONS from '@/utils/tools/country';

export default {
  data() {
    return {
      COUNTRY_OPTIONS,
      form: {
        country: '',
      },
      submitLoading: false,
    };
  },
  created() {
    getBrandInfo(this.$route.query.key).then((res) => {
      if (res.respCode === '20000' && res.data) {
        this.$store.dispatch('app/setBrandInfo', res.data);
      }
    }).catch(() => ({}));
  },
  methods: {
    next() {
      this.submitLoading = true;
      if (this.$route.params.type === 's') { // 标准收银台
        this.$router.push(`/checkout?c=${this.form.country}&key=${this.$route.query.key ? this.$route.query.key : this.$route.params.id}`);
      } else if (this.$route.params.type === 'a') {
        this.$router.push(`/aggregate?c=${this.form.country}&key=${this.$route.query.key ? this.$route.query.key : this.$route.params.id}`);
      } else {
        this.submitLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/element-variables.scss';
.address-wrapper {
  max-width: 640px;
  margin: 0 auto;
  padding-top: 79px;
  margin-bottom: 180px;
  .select-prefix {
    width: 160px;
    height: 40px;
    background: #FFF;
    border: 1px solid $color-border-light;
    box-sizing: border-box;
    line-height: 40px;
    text-align: left;
    padding-left: 10px;
    color: $color-text;
    font-family: inherit;
  }
  .tip-wrapper {
    display: flex;
    align-items: flex-start;
    .tip-icon {
      margin-right: 5px;
      margin-top: 1px;
    }
    .tip-text {
      font-size: 16px;
      font-family: inherit;
      font-weight: 400;
      color: var(--color-primary);
      line-height: 22px;
      vertical-align: top;
    }
  }
  .next-button {
    width: 400px;
    margin-top: 170px;
    max-width: 100%;
  }
}
</style>
